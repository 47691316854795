import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { IApiResponse } from '../utils/common.interface';
import { FilterSyncUrlHelper } from '../components/filters/filter-sync-url';

@Injectable({
    providedIn: 'root'
})
export class NftService {

    private searchKeyword: any;
    public searchKeywordObservable!: Observable<any>;
    private recentSearch: any;
    public recentSearchObservable!: Observable<any>;
    constructor(
        private http: HttpClient,
        private filterSyncUrlHelper: FilterSyncUrlHelper
    ) {
        // Search keyword
        this.searchKeyword = new BehaviorSubject('');
        this.searchKeywordObservable = this.searchKeyword.asObservable();
        this.recentSearch = new BehaviorSubject(false);
        this.recentSearchObservable = this.recentSearch.asObservable();
    }

    public getNfts(
        /**
         * Retrieves a list of NFTs based on the provided filters.
         * @param page The page number to retrieve (default: 1).
         * @param limit The number of items per page (default: 10).
         * @param searchKey The search keyword to filter NFTs.
         * @param collections The list of collection IDs to filter NFTs.
         * @param location The list of locations to filter NFTs.
         * @param category The list of categories to filter NFTs.
         * @param sale The sale type to filter NFTs (e.g. 'sale', 'auction').
         * @param loan The loan type to filter NFTs (e.g. 'loan', 'collateral').
         * @param from The minimum price range for filtering NFTs.
         * @param to The maximum price range for filtering NFTs.
         * @param appraisal_from The minimum appraisal value for filtering NFTs.
         * @param appraisal_to The maximum appraisal value for filtering NFTs.
         * @param sort The sorting criteria for the NFTs.
         * @param partition The partitioning key for the NFTs.
         * @param option - option key eg. all
         * @returns An Observable of the API response with the list of NFTs.
         */
        page: number,
        limit: number,
        searchKey: string,
        collections: any[],
        location: any[],
        category: any[],
        sale: string,
        loan: string,
        from: number,
        to: number,
        appraisal_from: number,
        appraisal_to: number,
        sort: any,
        partition: string,
        brokerSale: string,
        secondarySale: string,
        option?: string,
    ): Observable<any> {
        // Build the query string by concatenating all parts
        const queryParams = [
            this.buildPaginationParams(page, limit),
            this.buildSearchKeyParam(searchKey),
            this.buildArrayParams('collections', collections),
            this.buildArrayParams('location', location),
            this.buildArrayParams('category', category),
            this.buildStringParams('for_sale', sale),
            this.buildStringParams('broker_sale', brokerSale),
            this.buildStringParams('secondary_sale', secondarySale),
            this.buildStringParams('for_loan', loan),
            this.buildNumberParams('from', from, 0),
            this.buildNumberParams('to', to, 0),
            this.buildNumberParams('appraisal_from', appraisal_from, 0),
            this.buildNumberParams('appraisal_to', appraisal_to, 0),
            this.buildSortParams(sort),
            this.buildStringParams('partition', partition)
        ].filter(Boolean)  // Remove empty parameters
            .join('&');
        this.filterSyncUrlHelper.getAppliedFilterQueries(option ? option : queryParams);
        const apiUrl = `${environment.API_BASE_URL_V2}/nfts?${queryParams}`;
        // Make the HTTP GET request
        return this.http.get(apiUrl);
    }

    getNft(collectionAddress: string, tokenId: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/nft?collection_address=${collectionAddress}&token_id=${tokenId}`);
    }

    getNftById(id: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/nft-by-id?id=${id}`);
    }


    /**
     * Gets collections
     * @param {any} category
     * @param {any} location
     * @returns
     */getCollections(category?: any, location?: any) {
        let params: string[] = [];

        if (category !== undefined) {
            // Directly append the decoded category without encoding it
            params.push(`category=${category}`);
        }

        if (location !== undefined) {
            // Directly append the decoded location without encoding it
            params.push(`location=${location}`);
        }

        // Manually construct the query string
        const queryString = params.length > 0 ? `?${params.join('&')}` : '';
        const url = `${environment.API_BASE_URL}/user/collections${queryString}`;

        return this.http.get(url);
    }



    getNftTraits() {
        this.filterSyncUrlHelper.getAppliedFilterQueries('all');
        return this.http.get(`${environment.API_BASE_URL}/user/nfttraits`);
    }

    /**
     * gets the default selected nfts from management app, for each category
     * @return{Observable<IApiResponse>}
     */
    getDefaultNfts(): Observable<IApiResponse> {
        this.filterSyncUrlHelper.getAppliedFilterQueries('all');
        return this.http.get<IApiResponse>(`${environment.API_BASE_URL_V2}/selected-nfts`);
    }


    /**
     * Gets the minimum and maximum price value for NFTs
     * @param {number} [page] - The page number for pagination
     * @param {number} [limit] - The number of results per page
     * @param {string} [searchKey] - The search keyword to filter NFTs
     * @param {any[]} [collections] - List of collections to filter NFTs
     * @param {any[]} [location] - List of locations to filter NFTs
     * @param {any[]} [category] - List of categories to filter NFTs
     * @param {string} [sale] - Sale type filter (e.g., 'sale', 'auction')
     * @param {string} [loan] - Loan type filter
     * @param {number} [from] - Minimum price range
     * @param {number} [to] - Maximum price range
     * @param {number} [appraisal_from] - Minimum appraisal value
     * @param {number} [appraisal_to] - Maximum appraisal value
     * @param {any} [sort] - Sorting criteria
     * @param {string} [partition] - Partitioning key
     * @returns {Observable<IApiResponse>} Observable of the API response with min/max price details
     */
    getMinMaxNftsPrice(
        page?: number,
        limit?: number,
        searchKey?: string,
        collections?: any[],
        location?: any[],
        category?: any[],
        sale?: string,
        loan?: string,
        from?: number,
        to?: number,
        appraisal_from?: number,
        appraisal_to?: number,
        sort?: any,
        partition?: string,
        brokerSale?: any,
        SecondarySale?: any
    ): Observable<IApiResponse> {
        let sortByName = sort?.type === 'name' ? sort?.value : '';
        let sortByPrice = sort?.type === 'price' ? sort?.value : '';
        let sortByAppraisal = sort?.type === 'appraisal' ? sort?.value : '';
        let sortByPurchase = sort?.type === 'purchase' ? sort?.value : '';
        let sortByBid = sort?.type === 'bid' ? sort?.value : '';
        let sortByTransfer = sort?.type === 'transfer' ? sort?.value : '';
        let sortByOnLoan = sort?.type === 'onLoan' ? sort?.value : '';
        let sortByForLoan = sort?.type === 'forLoan' ? sort?.value : '';

        const params: any = {
            page,
            limit,
            key: searchKey,
            collections,
            location,
            category,
            for_sale: sale,
            for_loan: loan,
            from,
            to,
            appraisal_from,
            appraisal_to,
            name_sort: sortByName,
            appraisal_sort: sortByAppraisal,
            price_sort: sortByPrice,
            purchase_sort: sortByPurchase,
            forloan_sort: sortByForLoan,
            onloan_sort: sortByOnLoan,
            transfer_sort: sortByTransfer,
            bid_sort: sortByBid,
            partition,
            broker_sale: brokerSale,
            secondary_sale: SecondarySale
        };

        // Remove undefined or empty string parameters
        Object.keys(params).forEach(key => {
            if (params[key] === undefined || params[key] === '') {
                delete params[key];
            }
        });

        const queryString = new URLSearchParams(params).toString();
        const url = `${environment.API_BASE_URL_V2}/maximum-minimum-value?${queryString}`;

        return this.http.get<IApiResponse>(url);
    }

    getNftsByOwner(address: string, page: number, limit: number, searchKey: string, collections: any[], location: any[], category: any[], sale: string, loan: string, from: number, to: number, appraisal_from: number, appraisal_to: number, sort: any) {
        let sortByName = sort?.type === 'name' ? sort?.value : '';
        let sortByPrice = sort?.type === 'price' ? sort?.value : '';
        let sortByAppraisal = sort?.type === 'appraisal' ? sort?.value : '';
        return this.http.get(`${environment.API_BASE_URL}/user/nfts/owner?address=${address}&page=${page}&limit=${limit}&key=${searchKey}&collections=${collections}&location=${location}&category=${category}&for_sale=${sale}&for_loan=${loan}&from=${from}&to=${to}&appraisal_from=${appraisal_from}&appraisal_to=${appraisal_to}&name_sort=${sortByName}&appraisal_sort=${sortByAppraisal}&price_sort=${sortByPrice}`);
    }



    getInactiveNftsByOwner(address: string, page: number, limit: number) {
        return this.http.get(`${environment.API_BASE_URL}/user/nfts/owner/no-sale-loan?address=${address}&page=${page}&limit=${limit}`);
    }

    searchNft(keyword: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/nfts/search?key=${keyword}`);
    }

    setSearchKeyword(data: string) {
        this.searchKeyword.next(data);
    }

    /**
     * Gets signature for lazy mint order
     * @param order
     * @returns
     */
    getSignatureForLazyMintOrder(order: any) {
        return this.http.post(`${environment.API_BASE_URL}/user/lazy-mint-signature`, order);
    }

    getUserNftsCount(userId: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/list-user-nfts-count?id=${userId}`);
    }

    transferNft(params: any) {
        return this.http.post(`${environment.API_BASE_URL}/user/nft/transfer-nft`, params);
    }

    getSalePrice() {
        return this.http.get(`${environment.API_BASE_URL}/user/sale-price`);
    }

    getSalePriceByUser(address: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/sale-price-by-user?owner_address=${address}`);
    }

    getMaxSalePrice() {
        return this.http.get(`${environment.API_BASE_URL}/user/maximum-sale-price`);
    }

    getMaxSalePriceByUser(address: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/maximum-sale-price-by-user?owner_address=${address}`);
    }

    /**
     *
     * @param{string} collectionAddress
     * @param{string} tokenId
     * @param{string} id
     * @param{number}page
     * @param{number}limit
     * @return{{}}
     */
    getNftAnalytics(collectionAddress: string, tokenId: string, id: string, page?: number, limit?: number): Observable<IApiResponse> {
        const paramsArray = [
            { key: 'collection_address', value: collectionAddress },
            { key: 'token_id', value: tokenId },
            { key: 'id', value: id },
            { key: 'page', value: page },
            { key: 'limit', value: limit }
        ];

        let url = `${environment.API_BASE_URL}/user/nft/sale-history?`;

        paramsArray.forEach(param => {
            if (param.value !== '' && param.value !== undefined && param.value !== null) {
                url += `${param.key}=${param.value}&`;
            }
        });

        // Remove the last '&' if present
        url = url.endsWith('&') ? url.slice(0, -1) : url;

        return this.http.get<IApiResponse>(url);
    }

    /**
     * Gets recent search keywords
     * @param {string} id
     */
    getRecentSearch(id: string) {
        return this.http.get(`${environment.API_BASE_URL}/user/search-history?id=${id}`);
    }

    /**
     * Recents search status
     * @param {boolean} status
     */
    recentSearchStatus(status: boolean) {
        this.recentSearch.next(status)
    }

    /**
     * get holding nfts /assets
     * @param{string} address
     * @param{number} page
     * @param{number} limit
     * @param{string} searchKey
     * @param{any[]} collections
     * @param{any[]} location
     * @param{any[]} category
     * @param{string} sale
     * @param{string} loan
     * @param{number} from
     * @param{number} to
     * @param{numnber} appraisal_from
     * @param{number} appraisal_to
     * @param{any} sort
     * @return{Observable<IApiResponse>}
     */

    getHoldingNftsByOwner(address: string, page: number, limit: number, searchKey: string, collections: any[], location: any[], category: any[], sale: string, loan: string, from: number, to: number, appraisal_from: number, appraisal_to: number, sort: any): Observable<IApiResponse> {
        let sortByName = sort?.type === 'name' ? sort?.value : '';
        let sortByPrice = sort?.type === 'price' ? sort?.value : '';
        let sortByAppraisal = sort?.type === 'appraisal' ? sort?.value : '';
        let sortByPurchaseDate = sort?.type === 'purchase_sort' ? sort?.value : ''
        // TODO: Encode All Query Params.
        let collectionQuery = this.buildArrayParams('collections', collections)
        collectionQuery = collectionQuery ? `&${collectionQuery}` : ''
        return this.http.get<IApiResponse>(`${environment.API_BASE_URL}/user/nfts/holding-assets?address=${address}&page=${page}&limit=${limit}&key=${searchKey}&location=${location}&category=${category}&for_sale=${sale}&for_loan=${loan}&from=${from}&to=${to}&appraisal_from=${appraisal_from}&appraisal_to=${appraisal_to}&name_sort=${sortByName}&appraisal_sort=${sortByAppraisal}&price_sort=${sortByPrice}&purchase_sort=${sortByPurchaseDate}${collectionQuery}`);
    }

    /**
     * get delivered nfts/assets
     * @param{string} userId
     * @param{number} page
     * @param{number} limit
     * @return{Observable<IApiResponse>}
     */

    getDeliveryNftsByOwner(userId: string, page: number, limit: number) {
        return this.http.get<IApiResponse>(`${environment.API_BASE_URL}/user/nfts/delivery-assets?id=${userId}&page=${page}&limit=${limit}`);
    }


    /**
     * Build pagination query parameters.
     */
    buildPaginationParams(page: number, limit: number): string {
        return `page=${page}&limit=${limit}`;
    }

    /**
     * Build a query parameter for search key.
     */
    buildSearchKeyParam(searchKey: string): string {
        return searchKey ? `key=${encodeURIComponent(searchKey)}` : '';
    }

    /**
     * Build a query parameter for string-based values (e.g., sale, loan).
     */
    buildStringParams(key: string, value: string): string {
        return value ? `${key}=${encodeURIComponent(value)}` : '';
    }

    /**
     * Build a query parameter for number-based values, defaulting to a specified value.
     */
    buildNumberParams(key: string, value: number, defaultValue: number): string {
        return `${key}=${value || value === 0 ? encodeURIComponent(value) : defaultValue}`;
    }

    /**
     * Build query parameters for array-based values (e.g., collections, location).
     */
    buildArrayParams(key: string, values: any[]): string {
        if (values && values.length > 0) {
            const encodedValues = values.map(value => encodeURIComponent(value)).join(',');
            return `${key}=${encodedValues}`;
        }
        return '';
    }

    /**
     * Build sorting query parameters dynamically.
     */
    buildSortParams(sort: any): string {
        if (!sort) return '';

        const sortFields = ['name', 'price', 'appraisal', 'purchase', 'bid', 'transfer', 'onloan', 'forloan'];
        return sortFields
            .map(type => sort.type.toLowerCase() === type ? `${type}_sort=${encodeURIComponent(sort.value)}` : '')
            .filter(Boolean)  // Remove empty parameters
            .join('&');
    }

}

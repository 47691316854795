import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attribute'
})
export class AttributePipe implements PipeTransform {

  transform(attributes: any[], ...attributeNames: string[]): string | null {
    if (!attributes || !attributeNames.length) return null;

    // Normalize attributeNames by removing special characters
    const normalizedNames = attributeNames.map(name => this.normalizeKey(name));

    const attribute = attributes.find(attr => 
      attr?.key && normalizedNames.some(name => this.normalizeKey(attr.key) === name)
    );

    return attribute ? attribute.value : null;
  }

  // Helper method to remove special characters from the key
  private normalizeKey(key: string): string {
    return key.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
  }

}

/**
 * Checks if user is verified and valid.
 * If user is not verified or valid, it will open the verification modal.
 * @param user - User object.
 * @param verificationModal - Bootstrap modal object.
 * @returns {boolean} - True if user is verified and valid, false otherwise.
 */
export function isUserVerifiedAndValid(user: any, verificationModal: any, regulated: boolean): boolean {
    if (regulated === false && (user?.email_verified === false || user?.is_valid === false)) {
        verificationModal?.show();
        return false;
    }
    return true;
}

/**
 * Handles array itrator
 * @param value 
 * @param action 
 * @param callBack 
 */
export async function handleArrayItrator(value: Array<{ [key: string]: any }>, action: string[], callBack: (result: any) => any) {
    let overLoppedvalues: any
    overLoopValue([value, action], async (status: boolean) => {
        if (status) {
            overLoppedvalues = await customizeArryaIteration(value, action)
            callBack(overLoppedvalues);
            return ''
        } else {
            return "value isn't an array!!.Kindly check your values. "
        }
    })

}

/**
 * Checks valueis array
 * @param value 
 * @returns  
 */
function checkValueisArray(value: any) {
    return Array.isArray(value)
}

/**
 * Overs loop value
 * @param request 
 * @param callBack 
 */
function overLoopValue(request: Array<{ [key: string]: any }> | string[], callBack: (status: boolean) => any) {
    let result: boolean = false
    request.forEach((res: any) => {
        if (checkValueisArray(res)) {
            result = true
        }
    })
    callBack(result)
}

/**
 * Customizes arrya iteration
 * @param requestedArray 
 * @param iterationKey 
 */
function customizeArryaIteration(requestedArray: any, iterationKey: string[]) {
    let result: any = []
    requestedArray.forEach((element: any) => {
        for (const key of iterationKey) {
            if (Object.prototype.hasOwnProperty.call(element, key)) {
                result.push(element[key])
            }
        }
    });
    return result
}

/**
 * Gets the respected key values
 * @param value 
 * @param givenRequest 
 */
async function getTheRespectedKeyValues(value: { [key: string]: any }, givenRequest: any) {
    let result = []
    for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
            result.push(givenRequest[key])
        }
    }
    return result;
}

export const FOUR_CORNER_WINE_URL = "https://v22-services.hub.wine/xml/4CW-REALWORLD-4EB84B"
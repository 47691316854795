import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forbidUppercase } from '../../Helper/custom-form-validators';
import { AccountService } from '../../services/account.service';
import { WebStorageService } from '../../services/web-storage.service';
import { EmailPreference, IApiResponse, UserPreferencesPayload } from '../../utils/common.interface';
import { NotificationService } from '../../services/notification.service';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-mailverfication',
    templateUrl: './mailverfication.component.html',
    styleUrls: ['./mailverfication.component.css']
})
export class MailverficationComponent implements OnInit {
    @Input() showMailVerificationMenu = true;
    submitted = false;
    emailForm!: FormGroup;
    emailSettingsForm!: FormGroup;
    showStepOne = false;
    showStepTwo = false;
    showStepThree = false;
    isEditing = false;
    @Output() closeModal = new EventEmitter();
    @Output() openModal = new EventEmitter();
    user!: { [key: string]: any };
    isDisabled = false;
    extractedPath = '';
    @Input() isCancelOption = false;
    queryParams: any;
    emailPreferences: any = [];
    apiProcessing = false;
    isPendingSignature = false;
    is_Triggered: boolean = false;
    is_Checked: { [key: string]: boolean } = {
        'agreement_checkbox': false
    }
    signature!: string;

    /**
     * constructor
     */
    constructor(
        private fb: FormBuilder,
        private accountService: AccountService,
        private toastr: ToastrService,
        private webStorageService: WebStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        public commonService: CommonService
    ) {
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['showMailVerificationMenu'] && changes['showMailVerificationMenu'].currentValue) {
            this.route.queryParams.subscribe(params => {
                const id = params['id'];
                const token = params['token'];
                if (id && token) {
                    this.confirmEmail(id, token);
                }
            });
        }
    }
    ngOnInit() {
        // Set extractedPath based on the presence of the `regulated` query parameter
        this.route.queryParams.subscribe(queryParams => {
            this.queryParams = queryParams;
            this.extractedPath = 'regulated' in this.queryParams ? window.location.pathname + window.location.search : '/dashboard';
        });

        this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
        this.emailForm = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email, forbidUppercase()]],
            wallet_address: [''],
            url: [this.extractedPath],

        })
        this.getEmailPreferenceByUserId();
        this.commonService.getDocument();
    }
    get f() { return this.emailForm?.controls as { [key: string]: AbstractControl }; }

    onSubmit() {
        this.extractedPath = 'regulated' in this.queryParams ? window.location.pathname + window.location.search : '/dashboard';
        this.emailForm.patchValue({
            wallet_address: this.user?.['wallet_address'],
            url: this.extractedPath
        });
        this.submitted = true;

        if (this.emailForm.invalid || !this.signature) {
            this.submitted = false;
            this.toastr.error(
                this.emailForm.invalid
                    ? 'Please fill all the required fields.'
                    : 'Please agree to the Privacy Policy and Terms of Service to proceed.'
            );
            this.emailForm.markAllAsTouched();
            return;
        }
        const payload = {
            ...this.emailForm.value,
            signature: this.signature
        }

        this.accountService.updateProfile(payload).subscribe({
            next: (response: IApiResponse) => {
                this.webStorageService.setLocalStorage('user', JSON.stringify(response?.data));
                this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
                this.showStepTwo = true;
                this.showStepOne = this.isEditing = false;
                this.emailForm.reset();
                this.submitted = false;
            },
            error: (error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.message || "Something went wrong, try again later.");
                this.submitted = false;
            }
        })
    }

    /**
   * resend verification email
   */
    resendVerificationEmail() {
        this.isDisabled = true;
        this.extractedPath = 'regulated' in this.queryParams ? window.location.pathname + window.location.search : '/dashboard';
        this.accountService.resendVerificationEmail({ email: this.user['email'], url: this.extractedPath }).subscribe({
            next: (response: IApiResponse) => {
                this.toastr.success(response.message);
                setTimeout(() => {
                    this.isDisabled = false;
                }, 10000); //
            },
            error: (error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.message || "Something went wrong, try again later.");
                this.isDisabled = false;
            }
        })
    }

    /**
     * on edit
     */
    onEdit() {
        this.showStepOne = this.isEditing = true;
        this.showStepTwo = false;
        this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
        this.emailForm.patchValue({
            first_name: this.user?.['first_name'],
            last_name: this.user?.['last_name'],
            email: this.user?.['email'],
        });

    }

    /**
     * close step one
     */
    closeStepOne() {
        this.showStepOne = this.isEditing = false;
        this.closeModal.emit();
        this.emailForm.reset()
    }






    /**
   * confirm email
   * @param {string}id
   * @param {string}token
   */
    private confirmEmail(id: string, token: string) {
        this.accountService.confirmEmail({ id: id, token: token }).subscribe({
            next: (response: IApiResponse) => {
                let userData = JSON.parse(this.webStorageService.getLocalStorage('user')!);
                userData.email_verified = true;
                userData.is_valid = true;
                this.webStorageService.setLocalStorage('user', JSON.stringify(userData));
                this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
                this.openModal.emit();
                if (!this.queryParams['regulated']) {
                    this.router.navigate(['/dashboard']);
                }
                this.showStepThree = true;
                this.toastr.success(response.message);

            },
            error: (error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.message || "Something went wrong, try again later.");
            }
        })
    }

    createFgForEmailSettings(emailPreferences: EmailPreference[]) {
        // Function to create a form group dynamically
        const formGroup: any = {};
        // Add each preference as a form control with its value
        emailPreferences.forEach((preference: EmailPreference) => {
            formGroup[preference.key] = [preference.value];
        });
        // Initialize the form with the dynamic controls
        this.emailSettingsForm = this.fb.group(formGroup);

    }
    getEmailPreferenceByUserId() {
        if (!this.user?.['_id']) { return; }
        this.notificationService.getEmailPreferencesByUserId(this.user['_id']).subscribe({
            next: (response: IApiResponse) => {
                this.emailPreferences = response?.data?.preferences;
                this.createFgForEmailSettings(response?.data?.preferences);
            },
            error: (error: HttpErrorResponse) => {
                this.toastr.error(error?.error?.message || "Failed to get email preferences. Please try again.");
            }
        })
    }

    saveSettings() {
        this.apiProcessing = true;
        const payload: UserPreferencesPayload = this.generatePreferences();
        this.notificationService.updateEmailPreferences(payload).subscribe({
            next: (response: IApiResponse) => {
                this.emailPreferences = response?.data?.preferences;
                this.toastr.success(response?.message || "Email preferences updated successfully.");
                this.createFgForEmailSettings(response?.data?.preferences);
                this.apiProcessing = false;
                this.closeModal.emit();
            },
            error: (error: HttpErrorResponse) => {
                this.apiProcessing = false;
                this.toastr.error(error?.error?.message || "Failed to update email preferences. Please try again.");
            }

        })
    }
    generatePreferences() {
        const emailPreferences = this.emailSettingsForm.getRawValue();
        const updatedPreferences = this.emailPreferences.map((preference: EmailPreference) => ({
            key: preference.key,
            name: preference.name,
            value: emailPreferences[preference.key],
            description: preference.description
        }))
        return {
            user_id: this.user['_id'],
            preferences: updatedPreferences
        }
    }
    selectAll() {
        const allSelected = this.checkSelectAll(); // Check if all preferences are selected
        this.emailPreferences.forEach((preference: EmailPreference) => {
            preference.value = !allSelected; // Toggle all preferences
        });
        this.createFgForEmailSettings(this.emailPreferences); // Update the form group
    }

    checkSelectAll(): boolean {
        const formValues = Object.values(this.emailSettingsForm?.value); // Convert form values to an array
        return formValues.every((value) => value); // Check if all values are true
    }
    /**
     * Handles the signature retrieval process when the agreement checkbox is clicked.
     * Prevents the default checkbox behavior until the signature is successfully retrieved.
     *
     * @param {Event} event - The click event triggered by the agreement checkbox.
     */
    triggerSignatureRetrieval(event: Event): void {
        event.preventDefault();
        if (!this.is_Triggered) {
            this.isPendingSignature = true;
            this.is_Triggered = true;
            this.commonService.getSignature().then(signature => {
                this.signature = signature;
                this.isPendingSignature = false;
                this.is_Checked['agreement_checkbox'] = true;
            }).catch((error) => {
                this.is_Checked['agreement_checkbox'] = false;
                this.isPendingSignature = false;
                this.is_Triggered = false;
                this.toastr.error('Please select the checkbox to sign the privacy policy and user aggreement to proceed.')
            })
        }
    }
}

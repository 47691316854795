import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { FilterSyncUrlHelper } from 'src/app/shared/components/filters/filter-sync-url';
import { FilterHelper } from 'src/app/shared/components/filters/filter.helper';
import { CookieUtils } from 'src/app/shared/Helper/cookie-utils';
import { AccountService } from 'src/app/shared/services/account.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { NftService } from 'src/app/shared/services/nft.service';
import { SocketService } from 'src/app/shared/services/socket.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { IApiResponse } from 'src/app/shared/utils/common.interface';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { FOUR_CORNER_WINE_URL } from '../../shared/Helper/common-utils'
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
    // Filter variables
    filters: any[] = []; // to show chips.this variable in stored in session storage
    showFilter: boolean = false;
    filterCollection: any[] = [];
    filterLocation: any[] = [];
    filterCategory: any[] = [];
    filterSale: any[] = [{ name: "For sale.", value: "yes", checked: false }, { name: "Not for sale.", value: "no", checked: false }];
    filterCollateral: any[] = [{ name: "In collateral", value: "yes", checked: false }, { name: "Not in collateral", value: "no", checked: false }];
    brokerSaleList: any[] = [{ name: "Broker Sale", value: "yes", checked: false }, { name: "Not in Broker Sale", value: "no", checked: false }];
    secondarySaleList: any[] = [{ name: "Broker Sale", value: "yes", checked: true, functionName: 'brokerSale' }, { name: "Secondary Sale", value: "yes", checked: true, functionName: 'secondarySale' }];
    filterPrice: any[] = [];
    filterAppraisal: any[] = [];
    currentGoldMarketPrice: number = 0;
    collections: any[] = [];
    categories: any[] = [];
    locations: any[] = [];
    currencies: any[] = [];
    currencyConversions: any[] = [];
    partition: string = '';
    page: number = 1;
    searchKeyword: string = '';
    isClearFilters: boolean = false;
    saleOption: any[] = [{ name: "For sale.", value: "yes", checked: false }, { name: "Not for sale.", value: "no", checked: false }];
    collateralOption: any[] = [{ name: "In collateral", value: "yes", checked: false }, { name: "Not in collateral", value: "no", checked: false }];
    priceSlider = {
        floor: 1,
        ceil: 1,
        minValue: 1,
        maxValue: 1,
        loader: false
    }
    appraisalSlider = {
        floor: 1,
        ceil: 1,
        minValue: 1,
        maxValue: 1,
        loader: false
    };
    isInSale: boolean = true;

    // sort variables
    showSort: boolean = false;
    sort: any = '';

    // loader variables
    loader: boolean = true;
    scrollLoader: boolean = false;
    nftTraitsLoader: boolean = false;
    collectionLoader: boolean = false;

    // other variables
    isGridView: boolean = true;
    regulated: boolean = false;
    account: any = {};
    user: any = {};
    items: any[] = [];
    nftsCount: number = 0;
    totalFilterCount: any = {};
    Math: any = Math;
    imageLoading: boolean = false;
    disableInfiniteScroll: boolean = true;
    searchKeywordObservable: any;
    showDefaultNfts = false;
    isSeeMoreClicked = false;
    isSpecificFilter = '';
    isFilterChanged = false;
    specificFilterType = '';
    custodialType = CustodialType;
    selectedCustodialType = "";
    private videoPlayCounts = new Map<HTMLVideoElement, number>();

    fourCornerWineUrl: string = FOUR_CORNER_WINE_URL

    constructor(
        private commonService: CommonService,
        private nftService: NftService,
        private accountService: AccountService,
        private webStorageService: WebStorageService,
        private toastr: ToastrService,
        private socketService: SocketService,
        private filterHelper: FilterHelper,
        private filterSyncUrlHelper: FilterSyncUrlHelper,
        private spinner: NgxSpinnerService,
        private router: Router
    ) { }



    ngOnInit(): void {
        this.browserBackButtonEvent(1)
        this.showDefaultNfts = JSON.parse(this.webStorageService.getItem('showDefaultNfts') || 'false');
        this.isFilterChanged = JSON.parse(this.webStorageService.getItem('isFilterChanged') || 'false');
        this.accountService.loaderStatusUpdate(true);
        this.spinner.show();
        this.account = this.webStorageService.getLocalStorage('account') != null ? JSON.parse(this.webStorageService.getLocalStorage('account') || '') : this.account;
        this.user = this.webStorageService.getLocalStorage('user') != null ? JSON.parse(this.webStorageService.getLocalStorage('user') || 'undefined') : this.user;
        this.regulated = JSON.parse(this.webStorageService.getLocalStorage('regulated') || 'true');
        this.filters = JSON.parse(this.webStorageService.getItem('filters') || '[]');
        // Assuming this.filters is defined and contains items with a 'type' property
        const filterSaleItems = this.filters.filter((item: any) => item.type === 'sale');
        const brokerSaleItems = this.filters.filter((item: any) => item.type === 'brokerSale');
        const secondarySalesItem = this.filters.filter((item: any) => item.type === 'secondarySale');
        // Create a set of values from the filtered items for easier lookup
        const filterSaleValues = new Set(filterSaleItems.map((x: any) => x.value));
        const brokerSaleValue = new Set(brokerSaleItems.map((x: any) => x.value));
        const secondarySaleValue = new Set(secondarySalesItem.map((x: any) => x.value));
        // Update filterSale based on the filtered values
        this.filterSale = this.filterSale.map(item => {
            const isChecked = filterSaleValues.has(item.value.toLowerCase());
            item.checked = isChecked; // Set checked based on existence in filterSaleValues
            return item;
        });
        this.secondarySaleList = this.secondarySaleList.map(item => {
            if (item.functionName == 'brokerSale') {
                const isChecked = brokerSaleValue.has(item.value.toLowerCase());
                item.checked = isChecked; // Set checked based on existence in filterSaleValues
            }
            return item;
        });
        this.secondarySaleList = this.secondarySaleList.map(item => {
            if (item.functionName == 'secondarySale') {
                const isChecked = secondarySaleValue.has(item.value.toLowerCase());
                item.checked = isChecked; // Set checked based on existence in filterSaleValues
            }
            return item;
        });
        this.searchKeywordObservable = this.nftService.searchKeywordObservable.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((searchQuery: string) => {
                this.loader = true;
                this.spinner.show();
                this.scrollLoader = false;
                if ((searchQuery !== "" && (this.searchKeyword !== searchQuery)) || (searchQuery === '' && this.searchKeyword !== searchQuery)) {
                    this.showDefaultNfts = false;
                    this.page = 1;
                    this.searchKeyword = searchQuery;
                    // set search filter
                    this.filters = this.filters.filter((item) => item.type !== 'search');
                    if (this.searchKeyword !== '') this.filters.push({ type: 'search', value: this.searchKeyword });
                    this.webStorageService.setItem('filters', JSON.stringify(this.filters));
                    // api call
                    return this.nftService.getNfts(this.page, this.partition != '' ? 10 : 10, this.searchKeyword,
                        this.filterCollection,
                        this.filterLocation,
                        this.filterCategory,
                        this.filterSale.some((item) => item.checked) ? this.filterSale.find((item) => item.checked).value : "",
                        this.filterCollateral.some((item) => item.checked) ? this.filterCollateral.find((item) => item.checked).value : "",
                        this.filterPrice.length > 0 ? this.filterPrice[0]?.minValue : 0,
                        this.filterPrice.length > 0 ? this.filterPrice[0]?.maxValue : 0,
                        this.filterAppraisal?.length > 0 ? this.filterAppraisal[0].minValue : 0,
                        this.filterAppraisal?.length > 0 ? this.filterAppraisal[0].maxValue : 0, this.sort, this.partition,
                        this.brokerSaleList.some((item) => item.checked) ? this.brokerSaleList.find((item) => item.checked).value : "",
                        this.secondarySaleList.some((item) => item.checked) ? this.secondarySaleList.find((item) => item.checked).value : "",
                    )
                } else {
                    return '';
                }
            })
        ).subscribe({
            next: async (response: any) => {
                this.nftService.recentSearchStatus(true);
                if (response !== '') this.setNfts(response);
            },
            error: (error) => {
                this.loader = false;
                this.spinner.hide();
            }
        })
        this.filters = JSON.parse(JSON.stringify(this.filterSyncUrlHelper.mapQueryParams()));
        this.isSeeMoreClicked = JSON.parse(this.webStorageService.getItem('isSeeMoreClicked') || 'false');
        if (this.filters.length === 0 || this.showDefaultNfts) {
            this.showDefaultNfts = true;
            this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
            this.getDefaultNfts();
        }
        this.commonService.showGridViewObservable.subscribe((response: boolean) => {
            this.isGridView = response;
        })
        this.commonService.setTabEmitter({ type: 'all-items' }); //here I changed The 'all-item' instead of 'marketplace' because of its affecting the active link in the side bar.

        this.applyInitialFilter();
        this.getCurrencies();
        this.getNftTraits();
        this.getMarketPrice();
    }

    /**
     * Get nft triats
     * @function getNftTraits
     */
    async getNftTraits() {
        this.nftTraitsLoader = true;
        this.categories = [];
        this.locations = [];
        this.nftService.getNftTraits().subscribe({
            next: async (response: any) => {
                this.filterCategory = [];
                // set categories
                let categories = response.data.find((item: any) => item.attribute === "Category").value;
                this.totalFilterCount.category = categories.length;

                // retain categories checked states
                let categoryFilters = this.filters.filter((item) => item.type === 'category');
                categories.map((item: any) => {
                    let exists = categoryFilters.length > 0 ? categoryFilters.some((filter: any) => filter.value === item) : true;
                    this.categories.push(JSON.parse(JSON.stringify({ name: item, checked: exists })));

                    if (exists) this.filterCategory.push(item);
                    if (categoryFilters.length === 0) {
                        this.filters.push({ type: 'category', value: item });
                    }
                })

                this.filterLocation = [];
                // set locations
                let locations = response.data.find((item: any) => item.attribute === "Location").value;
                this.totalFilterCount.location = locations.length;

                // retain locations checked states
                let locationFilters = this.filters.filter((item) => item.type === 'location');
                locations.map((item: any) => {
                    let exists = locationFilters.length > 0 ? locationFilters.some((filter: any) => filter.value === item) : true;
                    this.locations.push(JSON.parse(JSON.stringify({ name: item, checked: exists })));

                    if (exists) this.filterLocation.push(item);
                    if (locationFilters.length === 0) {
                        this.filters.push({ type: 'location', value: item });
                    }
                })
                this.categories = JSON.parse(JSON.stringify(this.unshiftSelectedItems(this.categories)));

                // get collections based on category and location
                await this.getCollectionsByCategoryLocation();
                this.getAllCollections();
            },
            error: (error: any) => {
                this.nftTraitsLoader = false;
                this.toastr.error(error?.data?.message || "Something went wrong, try again later.");
            }
        })
    }

    // Move selected items to top of the list
    unshiftSelectedItems(items: any[]) {
        let selectedItem = items.filter((item) => item.checked === true)
        let unselectedItem = items.filter((item) => item.checked === false)
        return [...selectedItem, ...unselectedItem]
    }

    /**
     * Get collections based on category and location
     * @function getCollectionsByCategoryLocation
     */
    getCollectionsByCategoryLocation(option?: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.collectionLoader = true;
            let existingCollections = this.collections;
            this.collections = [];

            this.nftService.getCollections(this.filterCategory, this.filterLocation).subscribe({
                next: (response: any) => {
                    this.filterCollection = [];
                    let collections = response.data;
                    this.totalFilterCount.collection = response.data.length;

                    // Retain collections checked states

                    let collectionFilters = this.filters.filter((item) => item.type === 'collection');
                    this.filters = this.filters.filter((item) => item.type !== 'collection');
                    collections.map((item: any) => {
                        let checked;
                        if (existingCollections.length > 0) {
                            let collection = existingCollections.find((existingCollection) => existingCollection.name === item.name || existingCollection.name === item.value);
                            checked = collection ? collection.checked : true;
                        } else if ((collectionFilters.length > 0)) {
                            let collection = collectionFilters.find((filter) => filter.value === item.name || filter.value === item.value);
                            checked = collection ? true : false;
                        }
                        else {
                            checked = true;
                        }
                        this.collectionLoader = false;
                        // Push item to collections with `name` as a consistent key
                        this.collections.push(JSON.parse(JSON.stringify({ name: item.name || item.value, ...item, checked })));
                        if (checked) {
                            this.filters.push({ type: 'collection', value: item.name || item.value, category: item.category || '' });
                            this.filterCollection.push(item.name || item.value);
                        }
                    });
                    this.webStorageService.setItem('filters', JSON.stringify(this.filters));
                    // Get NFTs based on filters
                    if (!this.showDefaultNfts) {
                        this.getNfts(option);
                    }
                    // Resolve the Promise once done
                    resolve();
                },
                error: (error: any) => {
                    this.collectionLoader = false;
                    reject(error);
                }
            });
        });
    }

    allCollections: any[] = [];
    /**
     * Retrieves all collections based on the current categories and locations.
     *
     * @return {void}
     */
    getAllCollections(): void {
        const categories = this.categories.map((item) => item.name);
        const locations = this.locations.map((item) => item.name);
        this.nftService.getCollections(categories, locations).subscribe({
            next: (response: any) => {
                this.allCollections = response.data;
                this.updateCheckedCollections();
            }
        })
    }
    /**
     * Updates the checked state of all collections based on the currently checked collections.
     *
     * @return {void}
     */
    updateCheckedCollections(): void {
        const findCheckedCollections = this.collections.filter((item) => item.checked === true);
        this.allCollections = this.allCollections.map((item) => {
            const checkedCollection = findCheckedCollections.find((collection) => collection.name === item.name);
            item.checked = checkedCollection ? true : false;
            return item;
        })

    }
    /**
     * Get default NFTs
     * @function getDefaultNfts
     */
    async getDefaultNfts() {
        this.spinner.show();
        this.page = 1;
        this.loader = true;
        this.spinner.show();
        this.scrollLoader = false;
        this.searchKeyword = '';
        this.nftService.setSearchKeyword('');
        // remove search from filter
        this.filters = this.filters.filter((item) => item.type !== 'search');
        this.filterSale = JSON.parse(JSON.stringify(this.filterSale));
        this.brokerSaleList = JSON.parse(JSON.stringify(this.brokerSaleList));
        this.secondarySaleList = JSON.parse(JSON.stringify(this.secondarySaleList));
        this.nftService.getDefaultNfts().subscribe({
            next: async (response: any) => {
                this.setNfts(response);
            },
            error: (error: any) => {
                this.loader = false;
                this.spinner.hide();
                this.toastr.error(error?.data?.message || "Something went wrong, try again later.");
            }
        });
    }


    /**
     * Get nfts
     * @function getNfts
     */
    getNfts(option?: string) {
        this.webStorageService.setItem('filters', JSON.stringify(this.filters));
        // Ensure only one loader is true at a time based on the page number
        if (this.page === 1) {
            this.loader = true;
            this.spinner.show();
            this.scrollLoader = false;
        } else {
            this.loader = false;
            this.spinner.hide();
            this.scrollLoader = true;
        }
        this.disableInfiniteScroll = true;
        const collectionFilters = this.filters.filter((item) => item.type === 'collection')
        const uniqueCollectionCategories = [...new Set(collectionFilters.map(obj => obj.category))];
        let limit = this.partition != '' || (this.filterCategory.length === 1 && this.filterCategory[0]?.toLowerCase() !== 'gold') || (this.filterCategory.length > 1 && uniqueCollectionCategories.length === 1) || this.isFilterChanged ? 10 : 10;
        this.nftService.getNfts(this.page, limit, this.searchKeyword,
            this.filterCollection,
            this.filterLocation,
            this.filterCategory,
            this.filters.some((item) => item.type === 'sale') ? this.filters.find((item) => item.type === 'sale').value : "",
            this.filterCollateral.some((item) => item.checked) ? this.filterCollateral.find((item) => item.checked).value : "",
            this.filterPrice.length > 0 ? this.filterPrice[0]?.minValue : 0,
            this.filterPrice.length > 0 ? this.filterPrice[0]?.maxValue : 0,
            this.filterAppraisal?.length > 0 ? this.filterAppraisal[0].minValue : 0,
            this.filterAppraisal?.length > 0 ? this.filterAppraisal[0].maxValue : 0, this.sort, this.partition,
            this.filters.some((item) => item.type === 'brokerSale') ? this.filters.find((item) => item.type === 'brokerSale').value : "",
            this.filters.some((item) => item.type === 'secondarySale') ? this.filters.find((item) => item.type === 'secondarySale').value : "",
            option
        ).subscribe({
            next: (response: any) => {
                this.setNfts(response);
            },
            error: (error: any) => {
                this.toastr.error(error?.data?.message || "Something went wrong, try again later.");
            }
        })
    }

    /**
     * Set nfts
     * @function setNfts
     */
    async setNfts(response: any) {
        this.getNftsMinMaxPrice(response);
        this.nftsCount = response.data?.total_item_counts;
        if (response.data?.items?.length > 0) {
            if (!this.page || this.page === 1 || (this.page === 1 && this.searchKeyword !== "")) this.items = [];
            if (response.data?.page > 1 && this.page) this.items[0].items.push(...response.data?.items[0]?.items || []);
            else this.items = response.data?.items || []
            this.currentGoldMarketPrice = 0;
            // NOTE: Infinite scroll is disabled when there is no next page available.
            // If the response indicates no 'next_page', disable infinite scrolling.
            this.disableInfiniteScroll = !response.data.next_page
            this.page = this.disableInfiniteScroll ? 1 : response.data.next_page;
            for (const item of response.data?.items) {
                for (const nft of item?.items) {
                    if (nft.on_loan) {
                        nft.defaulted = nft.loan_details?.status === 1 && !this.isLiveLoan(nft.loan_details)
                    }
                    nft.category = (nft.attributes.find((attr: any) => attr.key.toLowerCase() === 'category')).value?.toLowerCase()
                    // find image type
                    nft.fileType = nft.preview_image ? nft?.preview_image.split('.')[nft?.preview_image.split('.').length - 1] : nft?.primary_media.split('.')[nft?.primary_media.split('.').length - 1];
                    if (nft?.category === 'gold') {
                        if (this.currentGoldMarketPrice === 0) {
                            let response: any = await this.commonService.getGoldPrice();
                            this.currentGoldMarketPrice = response.data.gold_value;
                        }
                        let markupFee = nft?.attributes?.find((data: any) => data.key.toLowerCase() == 'markup fee');
                        let size = nft?.attributes?.find((data: any) => data.key.toLowerCase() == 'size');

                        if (markupFee && size) {
                            let { price, priceWithFee }: any = await this.commonService.calculateGoldValue(size?.value, markupFee?.value, this.currentGoldMarketPrice);
                            nft.marketPrice = price;
                            if (nft.sale_details && nft.lazy_mint) nft.sale_details.exchange_price = priceWithFee;
                        } else {
                            console.error("Markup Fee or Size may be missing, both of which are required to calculate the gold market price.")
                        }
                    }

                    if (nft.lazy_mint) {
                        if (nft.on_sale) nft.sale_details.price = await this.setExchangePrice(nft);
                    }
                }
            }
        } else {
            this.items = [];
            this.page = 1;
            this.nftsCount = 0;
        }
        this.loader = false;
        this.spinner.hide();
        this.scrollLoader = false;
        this.accountService.loaderStatusUpdate(false);
    }

    /**
     * set price and appraisal price in the filter
     * @param{IApiResponse}response
     */
    setPriceAppraisalPrice(response: IApiResponse) {
        let priceFilter = this.filters.find(item => item.type === 'price');
        this.priceSlider = {
            ...this.priceSlider,
            floor: Math.ceil(response.data?.minimum_sale_price) || 1,
            ceil: Math.ceil(response.data?.maximum_sale_price) || 1,
            minValue: Math.ceil(response.data?.minimum_sale_price) || priceFilter?.value?.minValue || 0,
            maxValue: Math.ceil(response.data?.maximum_sale_price) || priceFilter?.value?.maxValue || 0,
            loader: false
        }
        let appraisalFilter = this.filters.find(item => item.type === 'appraisal');
        this.appraisalSlider = {
            ...this.appraisalSlider,
            floor: Math.ceil(response.data?.minimum_appraisal_value) || 1,
            ceil: Math.ceil(response.data?.maximum_appraisal_value) || 1,
            minValue: Math.ceil(response.data?.minimum_appraisal_value) || appraisalFilter?.value?.minValue || 0,
            maxValue: Math.ceil(response.data?.maximum_appraisal_value) || appraisalFilter?.value?.maxValue || 0,
            loader: false
        }
    }

    isLiveLoan(loan: any) {
        let LOAN_IN_DAYS = this.account.chainId ? (environment as any)[this.account.chainId].LOAN_IN_DAYS : (environment as any)['DEFAULT_NETWORK'].LOAN_IN_DAYS;
        if (environment.ENVNAME === 'DEVELOPMENT') loan.end_date = moment(loan.start_date).clone().add(loan.duration, LOAN_IN_DAYS ? 'days' : 'hours').toISOString()
        return moment(moment().format("YYYY-MM-DD, hh:mm a"), 'YYYY-MM-DD, hh:mm a').isBefore(moment(moment(loan?.end_date).format("YYYY-MM-DD, hh:mm a"), 'YYYY-MM-DD, hh:mm a'))
    }

    /**
     * Get currencies
     * @function getCurrencies
     */
    getCurrencies() {
        this.commonService.getCurrencies().subscribe({
            next: async (response: any) => {
                this.currencies = response.data;
            },
            error: (error) => {
                this.toastr.error(error?.data?.message || "Something went wrong, try again later.");
            }
        })
    }

    /**
     * @function applySort
     * @param {string} type
     * @param {number} value
     */
    applySort(type: string, value: number, init: boolean = false) {
        this.sort = { type, value };

        // Remove any existing sort filters of the same type
        this.filters = this.filters.filter((filter) => filter.type !== 'sort');

        // Determine the name for the sort filter
        let name: string;
        switch (type) {
            case 'name':
                name = value === 1 ? 'By Name. (A → Z)' : 'By Name. (Z → A)';
                break;
            case 'price':
                name = value === 1 ? 'By Price. (0 → 9)' : 'By Price. (9 → 0)';
                break;
            case 'appraisal':
                name = value === 1 ? 'By Appraisal value. (0 → 9)' : 'By Appraisal value. (9 → 0)';
                break;
            case 'purchase':
                name = 'By Purchase';
                break;
            case 'bid':
                name = 'By Bid';
                break;
            case 'loan':
                name = 'By Loan';
                break;
            case 'transfer':
                name = 'By Transfer';
                break;
            case 'forLoan':
                name = 'For Loan';
                break;
            case 'onLoan':
                name = 'On Loan';
                break;
            default:
                name = '';
        }

        // Push the new sort filter
        if (name) {
            this.filters.push({ type: 'sort', value, name, sortType: type });
        }

        // Reset the page and fetch updated data if not initialized
        this.page = 1;
        if (!init) {
            this.getNfts();
        }
    }

    /**
     * @function clearSort
     */
    clearSort() {
        this.page = 1;
        let sortIndex = this.filters.findIndex((filter) => (filter.type === 'sort'));
        if (sortIndex !== -1) {
            this.filters.splice(sortIndex, 1);
            this.sort = '';
        }
        this.getNfts();
    }

    /**
     * @function filterEvent
     */
    filterEvent({ value, checked, action, type }: any) {
        this.loader = true;
        this.spinner.show();
        this.scrollLoader = false;
        this.showDefaultNfts = false;
        this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
        if (!this.isSeeMoreClicked) {
            this.isFilterChanged = true;
        } else {
            this.isFilterChanged = false;
        }
        this.webStorageService.setItem('isFilterChanged', this.isFilterChanged);
        if (checked || action === 'reset' || action === 'only' || type === 'price' || type === 'appraisal') {
            this.applyFilter({ value, action }, type)
        } else {
            this.removeFilter({ value }, type);
        }
    }

    /**
     * @function applyFilter
     * @param {object} event
     * @param {string} type
     * @param {boolean} showDefault
     */
    async applyFilter({ value, action }: any, type: string, showDefault = false, filterType?: string) {
        this.isSpecificFilter = '';
        this.specificFilterType = filterType || '';
        this.page = 1;
        if (type === 'category') {
            await this.applyCategoryFilter({ value, action }, showDefault);
        }
        if (type === 'location') {
            await this.applyLocationFilter({ value, action });
        }
        if (type === 'collection') {
            await this.applyCollectionFilter({ value, action });
        }
        if (type === 'sale') {
            this.defaultForSaleFilter(type, value);
            await this.applySaleFilter({ value, action });
        }
        if (type === 'brokerSale') {
            await this.applyDiffSale({ value, action }, type);
        }
        if (type === 'secondarySale') {
            await this.applyDiffSale({ value, action }, type);
        }
        if (type === 'collateral') {
            this.defaultCollateralFilter(type, value);
            await this.applyCollateralFilter({ value, action });
        }
        if (type === 'price') {
            await this.applyPriceFilter({ value, action });
        }
        if (type === 'appraisal') {
            await this.applyAppraisalFilter({ value, action });
        }
        if (action === 'clearAll') {
            await this.applyClearAllFilter();
        }
        if (type && type !== 'category' && !this.showDefaultNfts) {
            this.getNfts();
        } else if (this.showDefaultNfts) {
            this.getDefaultNfts();
        }
    }

    applyInitialFilter() {
        if (this.filters.length === 0) {
            let saleFilter = this.saleOption.find((item) => item.checked);
            this.applySaleFilter({ value: saleFilter?.value, action: 'reset' });
        } else {
            // retain partition value
            let partitionFilter = this.filters.find((item) => item.type === 'partition');
            partitionFilter && Object.keys(partitionFilter).length > 0 ? this.partition = partitionFilter?.value : this.partition = '';

            // retain existing sale filter
            let filterItem = this.filters.find((item) => item.type === 'sale');
            if (filterItem && Object.keys(filterItem).length > 0) this.applySaleFilter({ value: filterItem?.value });

            // retain existing sale filter
            let brokerSale = this.filters.find((item) => item.type === 'brokerSale');
            if (brokerSale && Object.keys(brokerSale).length > 0) this.applySaleFilter({ value: brokerSale?.value });
            // retain existing sale filter
            let secondarySale = this.filters.find((item) => item.type === 'secondarySale');
            if (secondarySale && Object.keys(secondarySale).length > 0) this.applySaleFilter({ value: secondarySale?.value });
            // retain existing collateral filter
            let collateralFilterItem = this.filters.find((item) => item.type === 'collateral');
            if (collateralFilterItem && Object.keys(collateralFilterItem).length > 0) this.applyCollateralFilter({ value: collateralFilterItem?.value });

            // retain price filter
            let priceFilter = this.filters.find((item) => item.type === 'price');
            if (priceFilter && Object.keys(priceFilter).length > 0) {
                this.filterPrice = [priceFilter.value];
                this.priceSlider = { ...this.priceSlider, floor: priceFilter.value?.minValue, ceil: priceFilter.value?.maxValue, minValue: priceFilter.value?.minValue, maxValue: priceFilter.value?.maxValue }
            }

            // retain appraisal filter
            let appraisalFilter = this.filters.find((item) => item.type === 'appraisal');
            if (appraisalFilter && Object.keys(appraisalFilter).length > 0) {
                this.filterAppraisal = [appraisalFilter.value];
                this.appraisalSlider = { ...this.appraisalSlider, floor: appraisalFilter.value?.minValue, ceil: appraisalFilter.value?.maxValue, minValue: appraisalFilter.value?.minValue, maxValue: appraisalFilter.value?.maxValue }
            }

            // retain search
            let searchFilter = this.filters.find((item) => item.type === 'search');
            this.searchKeyword = searchFilter?.value || '';
            if (this.searchKeyword !== '') this.nftService.setSearchKeyword(this.searchKeyword)

            // retain sort
            let sort = this.filters.find((item) => item.type === 'sort');
            if (sort && Object.keys(sort).length > 0) this.applySort(sort.sortType, sort.value, true);
        }
    }

    /**
     * @function applyCategoryFilter
     * @param {object} event
     * @param {boolean} showDefault
     */
    async applyCategoryFilter({ value, action }: any, showDefault: boolean = false) {
        this.partition = '';
        this.filters = this.filters.filter(item => item.type !== 'partition');
        switch (action) {
            case 'reset':
                showDefault = true;
                this.filterCategory = [];
                this.filters = this.filters.filter(item => item.type !== 'category');
                structuredClone(this.categories.map((category: any) => {
                    category.checked = true;
                    this.filterCategory.push(JSON.parse(JSON.stringify(category.name)));
                    this.filters.push({ type: 'category', value: JSON.parse(JSON.stringify(category.name)) });
                }));
                await this.applyLocationFilter({ value, action });
                this.isSeeMoreClicked = false;
                this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);
                this.showDefaultNfts = true;
                this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
                // this.filters = [];
                break;

            case 'only':
                this.filters = this.filters.filter(item => item.type !== 'category');
                this.filters.push({ type: 'category', value })
                this.filterCategory = [value];
                this.categories = JSON.parse(JSON.stringify(this.categories.map((category: any) => {
                    category.checked = category.name === value;
                    return category;
                })));
                break;

            default:
                // add category to filter category array and filters chip
                this.filterCategory.push(value);
                this.filters.push({ type: 'category', value });
                // check in categories array
                let categoryIndex = this.categories.findIndex((item) => item.name === value);
                this.categories[categoryIndex].checked = true;
                this.categories = structuredClone(this.categories);
                this.applyDefaultFilters();
                break;
        }
        this.setBothSaleOption(true)
        this.secondarySaleList.map((item: any) => {
            return item.checked = true
        })
        console.log(this.categories, "Before")
        this.webStorageService.setItem('filters', JSON.stringify(this.filters));
        this.categories = structuredClone(this.unshiftSelectedItems(this.categories));
        // this.showDefaultNfts && this.getDefaultNfts();    // this will get executed if 'All' or 'Back' is selected from the dashboard page or all category filters is selected.
        if (showDefault) {
            this.applyClearAllFilter();
            return
        }
        await this.getCollectionsByCategoryLocation();
        this.updateCheckedCollections();
        console.log(this.categories, "After")
    }
    /**
     * @function applyLocationFilter
     * @param {object} event
     */
    async applyLocationFilter({ value, action }: any) {
        switch (action) {
            case 'reset':
                this.filterLocation = [];
                this.filters = this.filters.filter(item => item.type !== 'location');
                structuredClone(this.locations.map((location: any) => {
                    location.checked = true;
                    this.filterLocation.push(location.name);
                    this.filters.push({ type: 'location', value: location.name });
                }))
                break;

            case 'only':
                this.filters = this.filters.filter(item => item.type !== 'location');
                this.filters.push({ type: 'location', value })
                this.filterLocation = [value];
                structuredClone(this.locations.map((location: any) => { location.checked = location.name === value }));
                break;

            default:
                // add location to filter location array and filters chip
                this.filterLocation.push(value);
                this.filters.push({ type: 'location', value });

                // check in locations array
                let locationIndex = this.locations.findIndex((item) => item.name === value);
                structuredClone(this.locations[locationIndex].checked = true);
                this.applyDefaultFilters();
                break;
        }
        this.webStorageService.setItem('filters', JSON.stringify(this.filters));

    }

    /**
     * @function applyCollectionFilter
     * @param {object} event
     */
    async applyCollectionFilter({ value, action }: any) {
        switch (action) {
            case 'reset':
                this.filterCollection = [];
                this.filters = this.filters.filter(item => item.type !== 'collection');
                this.collections.map((collection: any) => {
                    collection.checked = true;
                    this.filterCollection.push(collection.name);
                    this.filters.push({ type: 'collection', value: collection.name, category: collection.category });
                })
                break;

            case 'only':
                this.filters = this.filters.filter(item => item.type !== 'collection');
                this.filters.push({ type: 'collection', value })
                this.filterCollection = [value];
                this.collections.map((collection: any) => { collection.checked = collection.name === value });
                break;

            default:
                // add collection to filter collection array and filters chip
                this.filterCollection.push(value);

                // check in collections array
                let collectionIndex = this.collections.findIndex((item) => item.name === value);
                this.collections[collectionIndex].checked = true;
                this.filters.push({ type: 'collection', value, category: this.collections[collectionIndex].category });
                if (this.filterCollection.length === this.collections.length) {
                    this.showDefaultNfts = true;
                    this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
                    this.isSeeMoreClicked = false;
                    this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);
                }

                break;
        }
        this.webStorageService.setItem('filters', JSON.stringify(this.filters));

    }

    /**
     * @function applySaleFilter
     * @param {object} event
     */
    applySaleFilter({ value, action }: any) {
        // show or hide price filter and sort
        this.isInSale = value === 'yes' ? true : false;
        // remove collateral filter
        this.removeCollateralFilter();

        this.filters = this.filters.filter(item => item.type !== 'sale');
        switch (action) {
            case 'reset':
                this.removeBothSaleFilter('brokerSale')
                this.removeBothSaleFilter('secondarySale')
                this.filterSale.map(item => item.checked = false);
                this.filterSale = JSON.parse(JSON.stringify(this.filterSale));
                this.filters = this.filters.filter(item => item.sortType !== 'price');
                break;
            default:
                if (value == 'no') {
                    this.removeBothSaleFilter('brokerSale')
                    this.removeBothSaleFilter('secondarySale')
                    this.secondarySaleList.map(item => item.checked = false);
                    this.secondarySaleList = JSON.parse(JSON.stringify(this.secondarySaleList));
                }
                let { name } = this.saleOption.find((item) => item.value === value)
                this.filters.push({ type: 'sale', value, name: JSON.parse(JSON.stringify(name)) })
                break;
        }
    }

    /**
     * @function applySaleFilter
     * @param {object} event
     */
    applyDiffSale({ value, action }: any, type: any) {
        // show or hide price filter and sort
        // this.isInSale = value === 'yes' ? true : false;
        // remove collateral filter

        let findSaleResult: any = (filter: any) => {
            return filter.find((item: any) => {
                return item.type == 'sale'
            })
        }

        this.removeCollateralFilter();
        // let name;
        let saleName: any;
        let saleFilterDetails = findSaleResult(this.filters)
        saleName = this.secondarySaleList.find((item) => {
            if (item.functionName == type) {
                return item
            }
        })
        this.secondarySaleList.map((item) => {
            if (item.functionName == type) {
                return item.checked = value == "yes" ? true : false
            }
        })

        this.filters = this.filters.filter(item => item.type !== type);
        this.secondarySaleList = JSON.parse(JSON.stringify(this.secondarySaleList));
        switch (action) {
            case 'reset':
                this.secondarySaleList.map(item => item.checked = false);
                this.secondarySaleList = JSON.parse(JSON.stringify(this.secondarySaleList));
                this.filters = this.filters.filter(item => item.sortType !== 'price');
                break;
            default:
                if (saleFilterDetails.value == "no") {
                    this.removeBothSaleFilter('brokerSale')
                    this.removeBothSaleFilter('secondarySale')
                    this.secondarySaleList.map(item => item.checked = false);
                    this.secondarySaleList = JSON.parse(JSON.stringify(this.secondarySaleList));
                } else {
                    this.filters.push({ type: type, value, name: JSON.parse(JSON.stringify(saleName.name)) })
                }
                break;
        }
    }

    /**
     * @function applySaleFilter
     * @param {object} event
     */
    applyCollateralFilter({ value, action }: any) {
        // remove sale and price filter
        this.removeSaleFilter();
        this.removeBothSaleFilter('brokerSale')
        this.removeBothSaleFilter('secondarySale')
        this.removePriceFilter();
        // remove price sort
        this.filterPrice = []
        let priceSort = this.filters.filter((filter) => filter.type === 'sort' && filter.sortType === 'price');
        if (priceSort.length > 0) this.removeSort()
        this.isInSale = false

        this.filters = this.filters.filter(item => item.type !== 'collateral');
        switch (action) {
            case 'reset':
                // deep copy of objects to avoid memory sharing
                this.filterCollateral = JSON.parse(JSON.stringify(this.collateralOption));
                break;

            default:
                let { name } = this.collateralOption.find((item) => item.value === value)
                this.filterCollateral.map(item => item.checked = item.value === value)
                this.filters.push({ type: 'collateral', value, name })
                break;
        }
    }

    applyPriceFilter({ value, action }: any) {
        this.filters = this.filters.filter(item => item.type !== 'price');
        switch (action) {
            case 'reset':
                this.filterPrice = [];
                break;

            default:
                let { minValue, maxValue } = value;
                if (this.filterPrice[0]?.minValue !== minValue || this.filterPrice[0]?.maxValue !== maxValue) {
                    this.filterPrice = [{ minValue, maxValue }];
                    if (minValue != maxValue) this.filters.push({ type: 'price', value: { minValue, maxValue }, name: `${minValue} - ${maxValue}` });
                }
                break;
        }
    }

    applyAppraisalFilter({ value, action }: any) {
        this.filters = this.filters.filter(item => item.type !== 'appraisal');
        switch (action) {
            case 'reset':
                this.filterAppraisal = [];
                break;

            default:
                let { minValue, maxValue } = value;
                if (this.filterAppraisal?.[0]?.minValue !== minValue || this.filterAppraisal?.[0]?.maxValue !== maxValue) {
                    this.filterAppraisal = [{ minValue, maxValue }];
                    if (minValue != maxValue) this.filters.push({ type: 'appraisal', value: { minValue, maxValue }, name: `${minValue} - ${maxValue}` });
                }
                break;
        }
    }

    /**
     * @function applyClearAllFilter
     */
    applyClearAllFilter() {
        this.filters = [];
        this.filterCollection = [];
        // clear category filters
        this.filterCategory = this.categories.map((category) => {
            category.checked = true;
            return category.name;
        });
        this.categories = JSON.parse(JSON.stringify(this.categories));
        this.isSeeMoreClicked = false;
        this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);

        // clear location filters
        this.filterLocation = this.locations.map((location) => {
            location.checked = true;
            return location.name;
        });

        // clear sale filters
        this.filterSale = JSON.parse(JSON.stringify(this.saleOption));

        // clear collateral filters
        // deep copy of objects to avoid memory sharing
        this.filterCollateral = JSON.parse(JSON.stringify(this.collateralOption));
        this.removeBothSaleFilter('brokerSale')
        this.removeBothSaleFilter('secondarySale')
        // clear collection filters
        this.collections.forEach((collection) => collection.checked = true);

        this.getCollectionsByCategoryLocation('all');

        // clear price filter
        this.removePriceFilter();

        // clear appriasal filter
        this.removeAppraisalFilter();

        // clear search filter
        this.removeSearchFilter();
        this.applyInitialFilter();
    }

    /**
     * @function removeFilter
     */
    async removeFilter({ value }: any, type: string) {
        this.page = 1;

        if (type === 'category') this.removeCategoryFilter({ value })
        if (type === 'location') this.removeLocationFilter({ value })
        if (type === 'collection') this.removeCollectionFilter({ value })
        if (type === 'sale') this.removeSaleFilter();
        if (type === 'collateral') this.removeCollateralFilter();
        if (type === 'price') this.removePriceFilter();
        if (type === 'appraisal') this.removeAppraisalFilter();
        if (type === 'sort') this.removeSort();
        if (type === 'partition') this.removeCategoryPartition();
        if (type === 'search') this.removeSearchFilter();
        if (type === 'brokerSale') this.removeBothSaleFilter(type)
        if (type === 'secondarySale') this.removeBothSaleFilter(type)

        if (type && type !== 'category' && !this.showDefaultNfts) {
            this.getNfts();
        } else if (this.showDefaultNfts) {
            this.getDefaultNfts();
        }
    }

    /**
     * remove search filter
     */
    removeSearchFilter() {
        this.searchKeyword = '';
        this.nftService.setSearchKeyword('');
        // remove search from filter
        this.filters = this.filters.filter((item) => item.type !== 'search');
    }

    /**
     * @function removeCategoryFilter
     * @param {object} event
     */
    removeCategoryFilter({ value }: any) {
        // remove from filter category array
        this.filterCategory = this.filterCategory.filter(item => item !== value);

        // remove from filters chip
        const filterIndex = this.filters.findIndex((filter) => (filter.type === 'category' && filter.value === value));
        if (filterIndex !== -1) this.filters.splice(filterIndex, 1);

        // if all items are unchecked then check back all categories again
        // else uncheck from category array
        if (this.filterCategory.length === 0) {
            this.isSeeMoreClicked = false;
            this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);
            this.filterCategory = this.categories.map((item) => {
                item.checked = true;
                return item.name
            })
            this.categories = JSON.parse(JSON.stringify(this.categories));
        }
        else {
            let categoryIndex = this.categories.findIndex((item) => item.name === value);
            this.categories[categoryIndex].checked = false;
        }

        this.getCollectionsByCategoryLocation();
    }

    /**
     * @function removeLocationFilter
     * @param {object} event
     */
    removeLocationFilter({ value }: any) {
        // remove from filter location array
        this.filterLocation = this.filterLocation.filter(item => item !== value);

        // remove from filters chip
        const filterIndex = this.filters.findIndex((filter) => (filter.type === 'location' && filter.value === value));
        if (filterIndex !== -1) this.filters.splice(filterIndex, 1);

        // if all items are unchecked then check back all locations again
        // else uncheck from location array
        if (this.filterLocation.length === 0) {
            this.filterLocation = this.locations.map((item) => {
                item.checked = true;
                return item.name
            })
            this.locations = JSON.parse(JSON.stringify(this.locations));
        }
        else {
            let locationIndex = this.locations.findIndex((item) => item.name === value);
            structuredClone(this.locations[locationIndex].checked = false);
        }
    }

    /**
     * @function removeCollectionFilter
     * @param {object} event
     */
    removeCollectionFilter({ value }: any) {
        // remove from filter collection array
        this.filterCollection = this.filterCollection.filter(item => item !== value);

        // remove from filters chip
        const filterIndex = this.filters.findIndex((filter) => (filter.type === 'collection' && filter.value === value));
        if (filterIndex !== -1) this.filters.splice(filterIndex, 1);

        // if all items are unchecked then check back all collections again
        // else uncheck from collection array
        if (this.filterCollection.length === 0) {
            this.filterCollection = this.collections.map((item) => {
                item.checked = true;
                return item.name
            })
            this.collections = JSON.parse(JSON.stringify(this.collections));
        }
        else {
            let collectionIndex = this.collections.findIndex((item) => item.name === value);
            this.collections[collectionIndex].checked = false;
        }
    }

    /**
     * @function removeSaleFilter
     */
    removeSaleFilter() {
        this.isSeeMoreClicked = false;
        this.removeBothSaleFilter('brokerSale')
        this.removeBothSaleFilter('secondarySale')
        this.filters = this.filters.filter(item => item.type !== 'sale');
        let sale = this.filterSale.find(item => {
            return item.checked
        });
        sale && (sale.checked = false);
        this.filterSale = JSON.parse(JSON.stringify(this.saleOption));
        const isSaleFilterAvailable = this.filters.filter((x: any) => x.sortType === 'price');
        if (isSaleFilterAvailable.length > 0) {
            this.isInSale = false
            this.removeSort();
        }
    }

    /**
     * Params dashboard component
     * @param [type] 
     */
    removeBothSaleFilter(type?: any) {
        this.isSeeMoreClicked = false;
        this.filters = this.filters.filter(item => item.type !== type);
        this.secondarySaleList.map(item => {
            if (item.functionName == type) {
                return item.checked = false
            }
        });

        let saledDetails = this.secondarySaleList.filter((item: any) => {
            if (item.checked == false) {
                return item
            }
        }).length
        if (saledDetails == 2) {
            this.saleOption.map(item => {
                item.checked = false
                if (item.value == "no") {
                    return item.checked = true
                }
            });
            this.saleOption = JSON.parse(JSON.stringify(this.saleOption));
        }

        // sale && (sale.checked = false);
        this.secondarySaleList = JSON.parse(JSON.stringify(this.secondarySaleList));
    }

    /**
     * @function removeCollateralFilter
     */
    removeCollateralFilter() {
        this.filters = this.filters.filter(item => item.type !== 'collateral');
        this.filterCollateral = JSON.parse(JSON.stringify(this.collateralOption));
        let collateral = this.filterCollateral.find(item => item.checked);
        if (collateral) collateral.checked = false;
    }

    /**
     * @function removePriceFilter
     */
    removePriceFilter() {
        this.filters = this.filters.filter(item => item.type !== 'price');
        this.filterPrice = [];
    }

    /**
     * @function removeAppraisalFilter
     */
    removeAppraisalFilter() {
        this.filters = this.filters.filter(item => item.type !== 'appraisal');
        this.filterAppraisal = [];
    }

    /**
     * @function removeSort
     */
    removeSort() {
        this.sort = '';
        this.filters = this.filters.filter((filter) => filter.type !== 'sort');
    }

    /**
     * @function removeCategoryPartition
     * @param {object} event
     */
    removeCategoryPartition() {
        this.partition = '';
        this.page = 1;
        this.filters = this.filters.filter((filter) => filter.type !== 'partition');
    }

    /**
     * @function clearFilter
     * @param {object} filter
     */
    clearFilter(filter: any) {
        let { type, value } = filter;
        this.removeFilter({ value }, type);
        if (this.isSeeMoreClicked) {
            this.isSeeMoreClicked = true;
        } else {
            this.isSeeMoreClicked = false;
        }
        this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);
    }

    /**
     * @function clearFilters
     */
    clearFilters() {
        this.sort = '';
        this.applyFilter({ action: 'clearAll' }, '');
        if (this.isSeeMoreClicked) {
            this.isSeeMoreClicked = true;
        } else {
            this.isSeeMoreClicked = false;
        }
        this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);

    }

    /**
     * Sets tooltip based on screen size
     * @param {number} index
     */
    public setTooltipSize(index: number) {
        if ((<HTMLElement>document.getElementById(`tooltiptitle${index}`)).scrollHeight > 59) {
            (<HTMLElement>document.getElementById(`tooltipdescription${index}`)).style.display = 'block';
            (<HTMLElement>document.getElementById(`tooltiphead${index}`)).classList.add('text-content');

        }
        else {
            (<HTMLElement>document.getElementById(`tooltipdescription${index}`)).style.display = 'none';
            (<HTMLElement>document.getElementById(`tooltiphead${index}`)).classList.remove('text-content');
        }
    }



    seeMore(category: string) {
        this.isSpecificFilter = '';
        this.specificFilterType = category;
        this.loader = true;
        this.spinner.show();
        if (this.filterCategory.length > 1) {
            this.filters = this.filters.filter(item => item.type !== 'category')
            this.categories.map((item: any) => {
                item.checked = item.name === category;
            })
            this.categories = structuredClone(this.unshiftSelectedItems(this.categories));
            this.showDefaultNfts = false;
            this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
            this.isSeeMoreClicked = true;
            this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);
            this.isFilterChanged = false;
            this.webStorageService.setItem('isFilterChanged', this.isFilterChanged);

            this.filterCategory = [category]
            this.filters.push({ type: 'category', value: category });
            this.getCollectionsByCategoryLocation();
        } else {
            this.partition = category;
            this.filters.push({ type: 'partition', value: this.partition });
            this.getNfts();
        }
    }

    back() {
        this.page = 1;
        this.loader = true;
        this.spinner.show();
        this.scrollLoader = false;
        this.isSeeMoreClicked = false;
        this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked)
        this.showDefaultNfts = true;
        this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
        if (this.filterCategory.length === 1 && this.partition != '') {
            this.partition = '';
            this.filters = this.filters.filter(item => item.type !== 'partition');
            this.getNfts();
            return
        } else if (this.filterCategory.length === 1) {
            this.clearFilters();

            // this.getCollectionsByCategoryLocation();
        }
        return
    }

    getMarketPrice() {
        this.socketService.getMarketPrice().subscribe(async (marketPrice: any) => {
            marketPrice = marketPrice || this.currentGoldMarketPrice;
            if (marketPrice > 0) {
                for (const item of this.items) {
                    let goldNfts = item.items.filter((item: any) => item?.collections?.category?.toLowerCase() === 'gold');
                    goldNfts.map(async (nft: any) => {
                        let markupFee = nft?.attributes?.find((data: any) => data.key.toLowerCase() == 'markup fee');
                        let size = nft?.attributes?.find((data: any) => data.key.toLowerCase() == 'size');

                        if (markupFee && size) {
                            let { price, priceWithFee }: any = await this.commonService.calculateGoldValue(size?.value, markupFee?.value, marketPrice);
                            nft.marketPrice = price;

                            if (nft.on_sale) {
                                nft.sale_details.exchange_price = priceWithFee;
                                nft.sale_details.price = await this.setExchangePrice(nft);
                            }
                        } else {
                            console.error("Markup Fee or Size may be missing, both of which are required to calculate the gold market price.")
                        }
                    })
                }
            }
        });
    }

    /**
     * @function setExchangePrice
     * @param {object} nft
     * @returns {number} value
     */
    setExchangePrice = async (nft: any) => {
        let index = this.currencyConversions.findIndex((item) => item.address === nft.sale_details.currency_address);
        let value: number;
        if (index >= 0) {
            value = this.currencyConversions[index].value === 0 ? Math.ceil(nft.sale_details.exchange_price) : Math.ceil(nft.sale_details.exchange_price / this.currencyConversions[index].value);
        } else {
            let usdPrice = 0;
            try {
                let response: any = await this.commonService.getTokenPrice(nft.sale_details.currency_address);
                usdPrice = response[nft.sale_details.currency_address.toLowerCase()]?.usd || 1;
            } catch (error) {
                let currency = this.currencies.find((currency) => currency.address === nft.sale_details.currency_address);
                usdPrice = currency.usd_value || 1
            }
            this.currencyConversions.push({ address: nft.sale_details.currency_address, value: usdPrice });
            value = Math.ceil(nft.sale_details.exchange_price / usdPrice);
        }
        return value;
    }
    /**
     * Gets the appraisal value of an NFT.
     *
     * @param {any} nft - The NFT object containing details like category, lazy mint status, and sale details.
     * @returns {string | number} The appraisal value, which can be a number or a dash ('-') if the value is not available.
     */
    getAppraisalValue(nft: any): string | number {
        if (!nft) {
            return '-';
        }
        const isGoldCategory = nft.category === 'gold';
        const isLazyMint = isGoldCategory ? nft?.collections?.isLazyMint : nft?.lazy_mint
        const marketPrice = Math.ceil(nft?.marketPrice);
        const appraisalValue = Math.ceil(nft.appraisal_value)
        if (isLazyMint) {
            const exchangePrice = Math.ceil(nft.sale_details?.exchange_price);
            const salePrice = Math.ceil(nft.sale_details?.price);
            if (isGoldCategory) {
                return marketPrice || '-';
            } else {
                return exchangePrice > 0 ? salePrice : appraisalValue || '-';
            }
        } else {
            return appraisalValue || '-';
        }

    }
    /**
     * Determines whether an attribute should be displayed
     *
     * @param {any} nft - The NFT object containing various attributes.
     * @param {any} attribute - The attribute to check visibility for.
     * @returns {boolean} - Returns true if the attribute should be displayed; false otherwise.
     */
    showAttribute(nft: any, attribute: any): boolean {
        //Category
        const category = (nft?.attributes?.find((data: any) => data?.key?.toLowerCase() == 'category'))?.value?.toLowerCase();
        const isWine: boolean = category === 'wine';
        //Attribute
        const isQuantity: boolean = attribute.key?.toLowerCase() === 'quantity'
        const isPrice: boolean = attribute.key?.toLowerCase() === 'price'
        attribute.key = this.formatAttributeKey(attribute?.key);
        const hasGemstoneId = nft?.attributes.some((attribute: any) => attribute.key?.toLowerCase() === 'gemstone id');
        if (isQuantity) {
            return !isWine;
        } else if (isPrice) {
            return nft?.sale_details?.price > 0;
        } else if (hasGemstoneId && ['product code', 'vendor id', 'gemstone id'].includes(attribute?.key?.toLowerCase())) {
            return !hasGemstoneId;
        }
        else {
            return true;
        }
    }

    /**
     * Format an attribute key by adding spaces, converting to lowercase, and capitalizing
     * the first letter of each word.
     *
     * @param {string} attributeKey - The attribute key to format.
     * @returns {string} The formatted attribute key.
     */
    formatAttributeKey(attributeKey: string): string {
        const formattedKey = attributeKey
            .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before uppercase letters following lowercase
            .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Add space for PascalCase with consecutive capitals
            .toLowerCase() // Convert everything to lowercase
            .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
            .replace(/\s+/g, ' '); // Replace multiple spaces with a single space for cleanliness
        return formattedKey;
    }


    ngOnDestroy() {
        this.searchKeywordObservable?.unsubscribe();
        this.socketService?.unsubscribeEvents();
        this.browserBackButtonEvent(2)
    }

    /**
     * apply specific category from header
     */
    applySpecificCategory(filterType?: string) {
        this.page = 1;
        this.scrollLoader = false;
        this.isSpecificFilter = filterType || '';
        this.isSeeMoreClicked = true;
        this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked)
        this.showDefaultNfts = false;
        this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
        this.loader = true;
        this.spinner.show();

    }

    /**
     * gets minimum maximum price on initial page loading
     * @param{IapiResponse}response
     */
    async getNftsMinMaxPrice(response: IApiResponse) {
        const collectionFilters = this.filters.filter((item) => item.type === 'collection')
        const uniqueCollectionCategories = [...new Set(collectionFilters.map(obj => obj.category))];
        let limit = this.partition != '' || (this.filterCategory.length === 1 && this.filterCategory[0]?.toLowerCase() !== 'gold') || (this.filterCategory.length > 1 && uniqueCollectionCategories.length === 1) || this.isFilterChanged ? 10 : 10;
        let apiCall;
        if (this.showDefaultNfts) {
            apiCall = this.nftService.getMinMaxNftsPrice();
        } else {
            apiCall = this.nftService.getMinMaxNftsPrice(this.page, limit, this.searchKeyword,
                this.filterCollection,
                this.filterLocation,
                this.filterCategory,
                this.filterSale.some((item) => item.checked) ? this.filterSale.find((item) => item.checked).value : "",
                this.filterCollateral.some((item) => item.checked) ? this.filterCollateral.find((item) => item.checked).value : "",
                this.filterPrice.length > 0 ? this.filterPrice[0]?.minValue : 0,
                this.filterPrice.length > 0 ? this.filterPrice[0]?.maxValue : 0,
                this.filterAppraisal?.length > 0 ? this.filterAppraisal[0].minValue : 0,
                this.filterAppraisal?.length > 0 ? this.filterAppraisal[0].maxValue : 0, this.sort, this.partition,
                this.brokerSaleList.some((item) => item.checked) ? this.brokerSaleList.find((item) => item.checked).value : "",
                this.secondarySaleList.some((item) => item.checked) ? this.secondarySaleList.find((item) => item.checked).value : ""
            )
        }
        apiCall.subscribe({
            next: (responseData: IApiResponse) => {
                // this.nftsCount = responseData?.data?.total_item_counts;
                response = {
                    ...response,  // Spread the existing properties of response
                    data: {
                        ...response.data,  // Spread the existing data properties of response
                        ...responseData.data  // Merge the new data from responseData
                    }
                };
                this.setPriceAppraisalPrice(response);

            }
        });
    }

    /**
     * set default view
     */
    private applyDefaultFilters() {
        if (this.filterCategory.length === this.categories.length && this.filterLocation.length === this.locations.length) {
            this.showDefaultNfts = true;
            this.webStorageService.setItem('showDefaultNfts', this.showDefaultNfts);
            this.isSeeMoreClicked = false;
            this.webStorageService.setItem('isSeeMoreClicked', this.isSeeMoreClicked);
        }

    }
    /**
     * This function determines which custom sort options should be displayed based on the presence
     * of "for sale" and "collateral" filters.
     *
     * If "for sale" is set to "yes", neither "on loan" nor "for loan" should be shown.
     * If "collateral" is set to "yes", only "on loan" should be shown.
     * If "for sale" is set to "no" and "collateral" is set to "no", only "for loan" should be shown.
     * Otherwise, both "on loan" and "for loan" should be shown.
     *
     * @returns {string[]} An array of strings indicating which custom sort options should be displayed.
     *  - If the array is empty, neither "on loan" nor "for loan" should not be shown.
     *  - If the array contains 'onLoan', only "on loan" should be shown.
     *  - If the array contains 'forLoan', only "for loan" should be shown.
     *  - If the array contains both 'onLoan' and 'forLoan', both "on loan" and "for loan" should be shown.
     */
    enableSort(): string[] {
        const isForSale = this.filters.find((item: any) => item.type === 'sale');
        const isCollateral = this.filters.find((item: any) => item.type === "collateral");
        // If for sale is 'yes', hide both "on loan" and "for loan"
        if (isForSale?.value === 'yes') {
            const keysToRemove = ['On Loan', 'For Loan'];
            this.filters = this.filterHelper.removeCustomSort(this.filters, keysToRemove);
            return []; // Empty array means neither "on loan" nor "for loan" should not be shown
        }
        // If collateral is 'yes', show "on loan" but not "for loan"
        if (isCollateral?.value === 'yes') {
            const keysToRemove = ['On Loan'];
            this.filters = this.filterHelper.removeCustomSort(this.filters, keysToRemove);
            return ['onLoan'];
        }
        // If for sale is 'no' and collateral is 'no', show "for loan"
        if (isCollateral?.value === 'no') {
            const keysToRemove = ['For Loan'];
            this.filters = this.filterHelper.removeCustomSort(this.filters, keysToRemove);
            return ['forLoan'];
        }
        return ['onLoan', 'forLoan'];
    }
    /**
     * This function is used to reset the sort when the "for sale" filter is
     * changed. If the "for sale" filter is set to "yes", it will reset the
     * sort to "price" with value 1 (Ascending). If the "for sale" filter
     * is set to "no", it will reset the sort to an empty string.
     *
     * @param type The type of filter that was changed.
     * @param value The value of the filter that was changed.
     * @returns {void}
     */
    defaultForSaleFilter(type: string, value: string): void {
        this.sort = ''
        const condition: any = { sale: value === 'yes' && (this.sort.type === 'onLoan' || this.sort.type === 'forLoan') };
        condition[type] && (this.sort = '');
        // Reset this.sort if any condition is true
        if (value === 'yes') {
            this.setBothSaleOption()
            this.sort = {
                type: 'price',
                value: 1
            };
            const isPrice = this.filters.some((item: any) => item.name === 'By Price. (0 → 9)');
            if (!isPrice) {
                // Push to filters array
                this.filters.push({
                    type: 'sort',
                    value: 1,
                    name: 'By Price. (0 → 9)',
                    sortType: 'price'
                });
            }
        }
        if (value === 'no') {
            if (this.sort.sortType === 'price') {
                this.sort = ''; // Reset sort only if it's sorting by price
            }
            // Remove price-based filters
            this.filters = this.filterHelper.removeCustomSort(this.filters, ['By Price. (0 → 9)', 'By Price. (9 → 0)']);
        }
    }

    /**
     * Sets both sale option
     */
    setBothSaleOption(checkSaleStatus: boolean = false) {
        this.filters = this.filters.filter((item: any) => { return item.type != "brokerSale" });
        this.filters = this.filters.filter((item: any) => { return item.type != "secondarySale" })
        const isSaleApplied = this.filters.filter((item: any) => {
            if (item.type == "sale") {
                return item
            }
        })
        if (isSaleApplied.length >= 0 && ((isSaleApplied && isSaleApplied[0]?.value && isSaleApplied[0]?.value != 'no') || !checkSaleStatus)) {
            this.filters.push({
                "type": "brokerSale",
                "value": "yes",
                "name": "Broker Sale"
            })
            this.filters.push({
                "type": "secondarySale",
                "value": "yes",
                "name": "Secondary Sale"
            })
        }
    }

    /**
     * This function is used to reset the sort when the "collateral" filter is
     * changed. If the "collateral" filter is set to "yes", it will reset the
     * sort to an empty string if the current sort is "onLoan".
     * If the "collateral" filter is set to "no", it will reset the
     * sort to an empty string if the current sort is "forLoan".
     *
     * @param type The type of filter that was changed.
     * @param value The value of the filter that was changed.
     * @returns {void}
     */
    defaultCollateralFilter(type: string, value: string): void {
        const condition: any = {
            collateral: ((value === 'yes' && this.sort.type === 'onLoan') || (value === 'no' && this.sort.type === 'forLoan'))
        };
        // Reset this.sort if any condition is true
        condition[type] && (this.sort = '');
    }


    /**
     * get price for non gold nfts
     * @param {{[key: string]: any}} nft
     */
    getPrice(nft: { [key: string]: any }): number {
        if (nft?.['lazy_mint']) {
            return Math.ceil(nft?.['sale_details']?.exchange_price) > 0
                ? Math.ceil(nft?.['sale_details']?.price)
                : Math.ceil(nft?.['appraisal_value']);
        }
        return Math.ceil(nft?.['sale_details']?.exchange_price);
    }

    /**
     * Navigates to the collection detail page when a collection is clicked from the "NFTs in collection" section.
     * @param event The click event.
     * @param collectionAddress The address of the collection.
     */
    navigateToCollectionPage(event: MouseEvent, collectionAddress: string): void {
        this.commonService.setTabEmitter({ type: 'all-items' });
        this.commonService.navigateToCollectionPage(event, collectionAddress);
    }

    /**
     * Selects a custodial type.
     * @param type The selected custodial type.
     */
    selectCustodialType(type: CustodialType) {
        this.selectedCustodialType = type;
    }
    emitCustodialType() {
        this.webStorageService.setLocalStorage('custodialTypeSelected', JSON.stringify(true));
        const isRegulated = JSON.parse(this.webStorageService.getLocalStorage('regulated') || 'true');
        if (
            (isRegulated && this.selectedCustodialType === this.custodialType.nonCustodial) ||
            (!isRegulated && this.selectedCustodialType === this.custodialType.custodial)
        ) {
            this.commonService.setCustodialType(this.selectedCustodialType);
        }
    }

    /**
     * Manages the welcome screen.
     * 
     * The welcome screen is only shown to users who have not visited the app before
     * and have not selected a custodial type. A cookie is set to keep track of
     * whether the user has visited the app before.
     * 
     * @returns true if the welcome screen should not be shown, false otherwise.
     */
    manageWelocomeScreen() {
        const hasVisited = CookieUtils.checkCookie('hasVisited');
        const custodialTypeSelected = JSON.parse(this.webStorageService.getLocalStorage('custodialTypeSelected') || 'false');
        if (hasVisited && custodialTypeSelected) {
            return true;
        } else {
            CookieUtils.setCookie('hasVisited', 'true', 1000); //Expire in 1000 days
            return false;
        }
    }
    /**
     * Add the event listener for the browser back button detection.
     * @param type 
     */
    browserBackButtonEvent(type: number) {
        if (type == 1) {
            window.addEventListener('popstate', (event: any) => {
                if (this.router.url == '/' || decodeURIComponent(this.router.url).includes('/?filter/')) {
                    this.applyFilter({ action: 'reset' }, 'category', true, 'all')
                }
            })
        }
    }
}

export enum CustodialType {
    custodial = 'custodial',
    nonCustodial = 'nonCustodial'
}

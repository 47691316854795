import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HomeNewRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegulatedInterceptor } from './core/interceptors/regulated.interceptor';
import { SharedModule } from './shared/shared.module';
import { ContractUtils } from './shared/utils/contract-utils';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        HomeNewRoutingModule,
        ModalModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        CarouselModule,
        BrowserAnimationsModule,
        NgxFileDropModule,
        ToastrModule.forRoot(
            {
                timeOut: 10000,
                closeButton: true,
                preventDuplicates: true
            }),
        SocialLoginModule,
        ClipboardModule,
        NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
        NgIdleModule.forRoot(),
        NgxSpinnerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RegulatedInterceptor, multi: true },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            environment.GOOGLE_LOGIN_PROVIDER
                        )
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },
        ContractUtils,
        provideRouter([], withComponentInputBinding())
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


